<script>
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import Table from '@cox2m/city-services-ui-components/src/components/Table.svelte';

  import {getCookieByName} from '@cox2m/city-services-ui-components/src/funcs.js';
  import {getHardwareInstallationData} from '../actions.js';
  import {menuOpts, user} from '../stores';
  import {onMount} from 'svelte';

  const token = getCookieByName(`${'ENV'}_token`);

  let rows = [];

  let loading = true;
  let error = false;

  const columns = [
    {label: 'Hardware Id', key: 'hardware_id', type: 'number'},
    {label: 'Meter Number', key: 'meter_number', type: 'number'},
    {label: 'Address', key: 'address', type: 'text'},
    {label: 'Last Measurement', key: 'last_reading', type: 'date'},
    {label: 'Reading', key: 'reading', type: 'number'},
    {label: 'Manufacturer', key: 'manufacturer', type: 'text'},
    {label: 'Meter Size', key: 'meter_size', type: 'text'},
    {label: 'Site Id', key: 'site_id', type: 'number'},
    {label: 'Leakage', key: 'leakage', type: 'text'}
  ];

  const setError = () => {
    error = true;
    loading = false;
  };

  const setRows = devices => {
    rows = [];
    if (devices.length) {
      devices.forEach(device => {
        rows = [
          ...rows,
          {
            hardware_id: device.hardwareId,
            meter_number: device.data['Meter Number'],
            address: device.data.Address || '',
            last_reading: device.data.reading_date || '',
            reading: device.data.Value || '',
            manufacturer: device.data['Meter Manufacturer'] || '',
            meter_size: device.data['Meter Size'] || '',
            site_id: device.data['Site Id'] || '',
            leakage: ''
          }
        ];
      });
    }
    loading = false;
  };

  onMount(async () => {
    const hardwareDevices = await getHardwareInstallationData(token, {
      hardwareType: 'WATER_METER'
    });
    if (hardwareDevices.fulfilled) {
      setRows(hardwareDevices.devices);
    } else {
      setError();
    }
  });
</script>

<style>
  .absolute-center {
    position: absolute;
    top: 50%;
    left: calc(
      50% - calc(var(--cox2m-spacing-7-units) + var(--cox2m-visual-corrector))
    );
  }
  :global(.filters-container .form-control) {
    max-width: 500px;
  }
  .dashboard-component-container {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 16px;
    padding: var(--cox2m-spacing-8-units) 0;
    min-height: 100%;
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
  }
</style>

<MainScreen title="Devices" menuOpt={$menuOpts} user={$user} appSlug="water-metering" showAlertsBanner={false}>
  <div slot="main-dashboard" class="dashboard-component-container">
    {#if loading}
      <div class="absolute-center d-inline-block">
        <LoadingSpinner />
      </div>
    {:else if error}
      <div class="w-100 text-center mt-5 ">
        <h3>We are sorry, we could not fetch the devices</h3>
      </div>
    {:else}
      <div>
        <h2 class="mb-4 px-5">Devices</h2>
        {#if rows.length}
          <div class="mt-5">
            <Table
              id="devices-table"
              {columns}
              {rows}
              pagination={true}
              itemsPerPage={20} />
          </div>
        {:else}
          <div class="w-100 text-center mt-5">
            <h3>There are no devices for this application</h3>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</MainScreen>
